<template>
  <div class="choch">
      <h1 class="choch__caption mb-2">
          The product is no longer available
      </h1>
      <p class="choch__description mb-7">
          The product you were looking for is no longer available in our shop.
      </p>
      <v-btn
        outlined
        color="#0A467E"
        tile
        elevation="0"
        :exact="true"
        text
        class="mb-8"
        :to="{name: 'Home'}"
      >
        <v-icon
        class="mr-2">
            mdi-arrow-left
        </v-icon>
        Назад
      </v-btn>
      <picture>
          <source :srcset="require('@/assets/img/choch_mobile.jpg')" media="(max-width: 600px)">
          <source :srcset="require('@/assets/img/choch_tablet.jpg')" media="(max-width: 1199px)">
          <source :srcset="require('@/assets/img/choch_desktop.jpg')" media="(max-width: 7000px)">
          <img class="choch__image" :src="require('@/assets/img/choch_desktop.jpg')">
      </picture>

  </div>
</template>

<script>
export default {
     name: "Error"
}
</script>

<style   lang="scss">
    .choch {
        padding: 60px 0;
        height: 100%;
        padding-bottom: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 600px) {
           padding: 0 15px;
        }

        &__caption {
            font-family: "Roboto Condensed", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 38px;
            text-align: center;
            color: #56565A;

            @media screen and (max-width: 600px) {
                font-size: 24px;
                line-height: 28px;
            }
        }

        &__description {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #56565A;

            @media screen and (max-width: 600px) {
                font-size: 15px;
                line-height: 20px;
            }
        }

        & picture {
            width: 100%;
            text-align: center;
            @media screen and (max-width: 600px) {
                width: calc(100% + 30px);
            }
        }

        &__image {
            @media screen and (max-width: 600px) {
               width: 100%;
            }
        }
    }
</style>